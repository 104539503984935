import { IMenuItem } from "./navigation.model";
import { PermissionService } from "../services/permission.service";
export let buywoodMenu: IMenuItem[] = [
  {
    module: ["Dashboard"],
    name: "Dashboard",
    type: "link",
    state: "dashboard",
  },
  {
    module: ["E-Commerce", "ECommrece Products", "E-commerce Products"],
    name: "Catalogue",
    type: "link",
    state: "e-commerce-product",
  },

  {
    name: "Orders",
    type: "dropDown",
    module: [
      "Waiting Payments", "Delivery Details", "Cancelled Details", "Billing Management"
    ],
    state: "",
    sub: [
      {
        name: "Waiting Payments",
        module: ["Waiting Payments"],
        state: "waiting-payments",
      },
      {
        name: "Waiting Dispatch",
        module: ["Waiting Dispatch"],
        state: "waiting-dispatch",
      },
      // { name: "Dispatch Details", module: ["Dispatch Details"], state: "dispatch-details" },
      {
        name: "Delivery Details",
        module: ["Delivery Details"],
        state: "delivery-details",
      },
      {
        name: "Cancelled Details",
        module: ["Cancelled Details"],
        state: "cancelled-details",
      },
      {
        name: "Billing Management",
        module: ["Billing Management"],
        state: "billing-management",
      },
    ],
  },
  {
    module: ["Enquiry"],
    name: "Enquiry",
    type: "link",
    state: "enquiry",
  },

  {
    module: ["Customer"],
    name: "Customers",
    type: "link",
    state: "customer-details",
  },
  {
    module: ["Employee"],
    name: "Employee",
    type: "link",
    state: "employee-details",
  },
  {
    module: ["Quote"],
    name: "Quote",
    type: "link",
    state: "quote",
  },
  {
    module: ["Payment Transaction"],
    name: "Transactions",
    type: "link",
    state: "payment-transaction",
  },
  {
    module: ["Refund"],
    name: "Refund",
    type: "link",
    state: "refund",
  },
  {
    name: "CMS",
    type: "dropDown",
    module: ["CMS Banner", "CMS Video", "CMS Greetings"],
    state: "",
    sub: [
      { name: "General Banner", module: ["CMS Banner",], state: "cms/banner" },
      { name: "Video", module: ["CMS Video",], state: "cms/video" },
      { name: "Greetings", module: ["CMS Greetings"], state: "cms/greeting" },
    ],
  },
  {
    name: "Ticket",
    type: "link",
    module: ["Ticket", "Tickets"],
    state: "ticket",
  },
  {
    name: "Reports",
    type: "dropDown",
    module: [
      'Order Report',
      "Refund Report",
      "Tickets Report",
      "Gst Report",
      "Ratings Review Report",
      "Customer Report",
      "Employee Report",
      "Product Report",
      "Stock Report",
      "Payment Transaction Report",

    ],
    state: "",
    sub: [
      {
        name: "Order Report",
        module: ['Order Report',],
        state: "reports/orders-report",
      },
      {
        name: "Refund Report",
        module: ["Refund Report",],
        state: "reports/refund-report",
      },
      {
        name: "Tickets Report",
        module: ["Tickets Report",],
        state: "reports/ticket-report",
      },
      {
        name: "GST Report",
        module: ["Gst Report",],
        state: "reports/gst-report",
      },
      {
        name: "Ratings & Review Report",
        module: ["Ratings Review Report",],
        state: "reports/ratings-review-report",
      },
      {
        name: "customer Report",
        module: ["Customer Report",],
        state: "reports/customer-report",
      },
      {
        name: "employee Report",
        module: ["Employee Report",],
        state: "reports/employee-report",
      },
      {
        name: "product Report",
        module: ["Product Report",],
        state: "reports/product-report",
      },
      {
        name: "Stock Report",
        module: ["Stock Report",],
        state: "reports/stock-report",
      },
      {
        name: "Payment Transaction Report",
        module: ["Payment Transaction Report",],
        state: "reports/payments",
      },
    ],
  },
  {
    name: "Settings",
    type: "link",
    module: ["Category", "Variant Type", "Delivery Management", "Delivery Charge","Expected Delivery Days", "Shipped Vendor", "Department",
      "QR Code","GST Percentage", "Other District Setting", 'Ratings & Review',
      "Coupon Code", "Manage Roles", "Manage Users", "Terms and conditions"
    ],
    state: "settings/category-subcategory",
    sub: [
      {
        name: "Settings",
        type: "link",
        module: ["Category"],
        state: "settings/category-subcategory",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Delivery Charge"],
        state: "settings/delivery-charge",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Expected Delivery Days"],
        state: "settings/expected-delivery",
      },
      // {
      //   name: "Settings",
      //   type: "link",
      //   module: ["Change Password"],
      //   state: "settings/change-password",
      // },
      {
        name: "Settings",
        type: "link",
        module: ["QR Code"],
        state: "settings/qr-code",
      },
      {
        name: "Settings",
        type: "link",
        module: ["department"],
        state: "settings/department",
      },
      {
        name: "Settings",
        type: "link",
        module: ["gstpercentage"],
        state: "settings/gst-percentage",
      },
      {
        name: "Settings",
        type: "link",
        module: ["couponcode"],
        state: "settings/couponcode",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Terms and conditions"],
        state: "/settings/terms-conditions",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Variant Type"],
        state: "settings/varianttype",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Activity Log"],
        state: "settings/activity-logs",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Activity Log"],
        state: "settings/other-district",
      },
    ],
  },
];
export let buywoodExpEmployeeMenu: IMenuItem[] = [
  {
    module: ["Enquiry"],
    name: "Enquiries",
    type: "link",
    state: "employee/enquiries",
  },
];
export let EmployeeMenu: IMenuItem[] = [
 {
    module: ["Enquiry"],
    name: "Enquiry",
    type: "link",
    state: "employee/enquiry",
  },
  {
    module: ["Quote"],
    name: "Quote",
    type: "link",
    state: "employee/quote",
  },
];