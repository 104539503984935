import { Injectable } from "@angular/core";
import { IEmployeeAPI } from "app/core/models/employee/api.model";
import { Adapter } from "../../adapters";
import { EmployeeAdapter } from "./employee.adapter.service";



@Injectable({
    providedIn: "root"
})

export class EmployeeApiAdapter implements Adapter<IEmployeeAPI> {

    constructor(private adapterPackage: EmployeeAdapter) {

    }

    adapt(response: any): IEmployeeAPI {

        let userData = this.adapterPackage.adapt(response.data);
        
        return {
            keyword: response.keyword,
            message: response.message,
            employee: userData,
            pageSize: response?.total_size ?? 0,
            page: response?.page ?? 0,
        }
    }

    adaptlist(response: any): IEmployeeAPI {
        let userData:any = [];
        response.data.forEach((element) => {
            userData.push(this.adapterPackage.adaptenquireylist(element));
        });
        return {
            keyword: response.keyword,
            message: response.message,
            employee: userData,
            pageSize: response?.filter_count ?? 0,
            page: response?.page ?? 0,
        }
    }

}